import { useEffect, useRef, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';

// Types & Hooks
import type { PrescriptionFlowStep } from 'types/prescription';
import { PRESCRIPTION_FLOW_STEP_ID } from 'const/prescription';
import { PrescriptionInfoFormProps } from 'components/prescription-info-form/prescription-info-form.props';
import { useAddressVerification } from 'hooks/useAddressVerification';
import { useGlobalLink } from 'hooks/useGlobalLink';

// Prescription Info
import {
    addTransferPrescriptionFlowTypeSelector,
    addTransferPrescriptionSelector,
    addTransferPrescriptionIsPhotoUploadedSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { savePrescriptionInfo } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { TransferPrescriptionPayload } from 'state/add-transfer-prescription/add-transfer-prescription.services';
import { addTransferPrescriptionRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { FailureUpdateProfileModalContent } from '../profile/intra-page-items/_profile-update-modal.item';

// Pharmacy Info
import { AddressParts } from 'state/usps/usps.reducers';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
import { accountAddAddressToProfileRoutine } from 'state/account/account.routines';
import { clearPhysicianSearchResults, resetPhysicianSearch } from 'state/physician/physician.reducer';
import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';

// UI Kit && Components
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import PharmacyInfoForm, { PharmacyInfoFormProps } from 'components/pharmacy-form/pharmacy-info-form-component';
import PrescriptionInfoForm from 'components/prescription-info-form/prescription-info-form.component';
import PrescriptionLayoutFormWrapper from 'components/prescription-layout-form-wrapper/prescription-layout-form-wrapper.component';
import BirdiModalContent, { OverflowModal } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { Checkbox } from 'ui-kit/checkbox';

const Prescription = ({ data }: { data: GatsbyTypes.PrescriptionDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);
    const currentFlowBackground = currentFlowType === 'Transfer' ? data.transferRx : data.newRx;
    const isPhotoUploaded = useSelector(addTransferPrescriptionIsPhotoUploadedSelector);
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const globalLink = useGlobalLink();
    const pillImage = data.successModalPillImage;

    const [isSubmittingFinalStep, setIsSubmittingFinalStep] = useState(false);
    const [isReviewed, setIsReviewed] = useState<boolean>(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isReviewed === true) {
            setError('');
        }
    }, [isReviewed]);

    // DRX-1599: Disabling the "New" workflow to avoid unexpected access.
    if (currentFlowType === 'New') {
        navigate('/secure/medicine-cabinet');
    }

    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };
    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addAddressModal.title')}
                        body={
                            <AddAddressForm
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'PrecriptionUpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                    handleAddNewAddressClick();
                                },
                                dataGALocation: 'PrescriptionAddressVerificationError'
                            }
                        ]
                    })
                );
            }
        });
    };

    const handlePrescriptionInfoSubmit = (values: any, setSubmitting: Function, suppressPriceModal: boolean) => {
        dispatch(clearPhysicianSearchResults());
        dispatch(savePrescriptionInfo(values));
        globalLink.setLastFormField('');
    };

    const formPrescriptionRef = useRef<FormikProps<PrescriptionInfoFormProps>>(null);
    const formPharmacyRef = useRef<FormikProps<PharmacyInfoFormProps>>(null);

    const handleFullSubmit = () => {
        if (!formPrescriptionRef.current || !formPharmacyRef.current) {
            return;
        }

        // Validate prescription and pharmacy form
        formPrescriptionRef.current.validateForm();
        formPharmacyRef.current.validateForm();

        // Submit
        formPrescriptionRef.current.submitForm();
        formPharmacyRef.current.submitForm();

        if (!isReviewed) {
            setError(t('components.checkbox.error'));
            return;
        } else {
            setError('');
        }

        Promise.all([formPrescriptionRef.current.validateForm(), formPharmacyRef.current.validateForm()]).then(
            (data) => {
                if (formPrescriptionRef.current?.isValid && formPharmacyRef.current?.isValid) {
                    setIsSubmittingFinalStep(true);
                    dispatch(
                        addTransferPrescriptionRoutine.trigger({
                            onSuccess: (prescriptionData: TransferPrescriptionPayload) => {
                                dispatch(resetPhysicianSearch());
                                navigate('/secure/prescription/confirmation');
                            },
                            onFailure: () => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        className: 'prescription-modal',
                                        contentClassName: 'overflow-modal',
                                        bodyContent: (
                                            <OverflowModal
                                                title={t('modals.newRX.error')}
                                                text={t('modals.newRX.failure')}
                                                image={pillImage}
                                                internalCtas={[
                                                    {
                                                        label: t('modals.newRX.labels.gotIt'),
                                                        variant: 'primary',
                                                        onClick: () => {
                                                            dispatch(closeModal({}));
                                                            navigate('/secure/prescription');
                                                        }
                                                    }
                                                ]}
                                            />
                                        ),
                                        onClose: () => navigate('/secure/prescription'),
                                        ctas: []
                                    })
                                );
                                setIsSubmittingFinalStep(false);
                            }
                        })
                    );
                }
            }
        );
    };

    const handleCancel = () => {
        navigate('/secure/medicine-cabinet');
    };

    const formSteps: PrescriptionFlowStep[] = [
        {
            stepId: PRESCRIPTION_FLOW_STEP_ID.PRESCRIPTION_DETAILS,
            step: !isPhotoUploaded && (
                <>
                    {}
                    <PrescriptionInfoForm ref={formPrescriptionRef} handleFormSubmit={handlePrescriptionInfoSubmit} />
                    <PharmacyInfoForm
                        ref={formPharmacyRef}
                        initialValues={{
                            pharmacyName: transferPrescriptionState.PharmacyName,
                            pharmacyAddress: transferPrescriptionState.PharmacyAddress,
                            pharmacyPhone: transferPrescriptionState.PharmacyPhone
                        }}
                    />
                    <Row className="d-flex mt-3 pb-3">
                        <Col>
                            <Checkbox
                                name="checkReviewed"
                                label={t('prescriptionLayout.checkboxReviewed')}
                                id={'check-reviewed'}
                                onCheckChanged={setIsReviewed}
                                onClick={setIsReviewed}
                                hasError={!!error}
                            />
                            {error && <div className="text-flamingo">{error}</div>}
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mt-3">
                        <Button
                            async
                            className="font-weight-bolder sm-full mr-3 ml-3"
                            label={t('prescriptionLayout.submit')}
                            type="submit"
                            onClick={handleFullSubmit}
                            disabled={isSubmittingFinalStep}
                            isBusy={isSubmittingFinalStep}
                        />
                    </Row>
                    <Row className="d-flex justify-content-center mt-3">
                        <Button
                            variant="link"
                            label={t('prescriptionLayout.cancel')}
                            type="submit"
                            className="mx-md-2 d-block sm-full d-sm-inline btn-underline"
                            onClick={handleCancel}
                        />
                    </Row>
                </>
            ),
            title: t('prescriptionInfoForm.title'),
            backgroundImage: currentFlowBackground,
            showNextButton: false,
            gtmVPV: {
                flowType: currentFlowType,
                pagePath: '/prescription-details'
            },
            dataGAFormName: `${currentFlowType} Rx - Prescription`,
            dataGAFormStepName: t('prescriptionInfoForm.title')
        }
    ];

    return (
        <PrescriptionLayoutFormWrapper
            eyebrowText={
                currentFlowType === 'New'
                    ? t('pages.addNewPrescription.eyebrowText')
                    : t('pages.transferPrescription.eyebrowText')
            }
            className="order-prescription-workflow-layout-form-wrapper"
            flowType={currentFlowType}
            steps={formSteps}
        />
    );
};

export default Prescription;

export const query = graphql`
    query PrescriptionData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        newRx: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        transferRx: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
