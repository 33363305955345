import React, { useContext } from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import './pharmacy-search-results-group-toggle.scss';

export interface PharmacySearchResultsGroupToggleProps {
    label: string;
    extraInfo: string;
    eventKey: string;
}

const PharmacySearchResultsGroupToggle: React.FunctionComponent<PharmacySearchResultsGroupToggleProps> = ({
    label,
    extraInfo,
    eventKey
}) => {
    const currentEventKey = useContext(AccordionContext);
    const toggleAccordion = useAccordionToggle(eventKey);
    const isExpanded = currentEventKey === eventKey;

    return (
        <div className="pharmacy-search-results-group-toggle cursor-pointer" onClick={toggleAccordion}>
            <label className="text-left m-0 cursor-pointer">
                <b>{label}</b> {extraInfo}
            </label>
            <ChevronIcon direction={isExpanded ? 'up' : 'down'} />
        </div>
    );
};

export default PharmacySearchResultsGroupToggle;
